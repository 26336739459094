import React from "react";
import loading from "./loading.gif";
import "./RandomQuote.css";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";

class RandomQuote extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      quotes: [[]],
      showLoader: true,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event) {
    this.setState({ showLoader: true });
    this.downloadQuote();
  }

  componentDidMount() {
    this.downloadQuote();
  }

  downloadQuote = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };

    fetch(
      "https://99tpj0um53.execute-api.us-east-2.amazonaws.com/dev/QuoteFarmApi-Test/randomquote",
      //"http://localhost:53886/randomquote",
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => this.setState({ quotes: data }))
      .then((_) => this.setState({ showLoader: false }))
      .catch(function (err) {
        console.log("Failed to fetch page: ", err);
      });
  };

  getLoader = () => {
    return (
      <div id="loading-div">
        <img src={loading} width="20" height="20" alt="loading..." />
      </div>
    );
  };

  getQuote() {
    if (this.state.showLoader) {
      return this.getLoader();
    } else {
      // var url =
      //   "/Authors/" + this.state.quotes[0].toString().split(" ").join("-");
      return (
        <div id="random-quote-text-div">
          <div id="randomQuoteHeader">Random quote of the day:</div>
          {this.state.quotes[1]}-
          <Button size="small" variant="outlined" style={{ margin: "5px" }}>
            <Link to={"/Authors/" + this.state.quotes[0].split(" ").join("-")}>
              <u>{this.state.quotes[0]}</u>
            </Link>
          </Button>
          <Button
            style={{ margin: "5px" }}
            size="small"
            variant="outlined"
            onClick={this.handleSubmit}
          >
            New Random Quote
          </Button>
          {/* <RefreshIcon></RefreshIcon> */}
        </div>
      );
    }
  }

  render() {
    return <div id="random-quote">{this.getQuote()}</div>;
  }
}

export default RandomQuote;
